const meterageListHeaderColumns = [
  {
    index: 0,
    name: '原始子目号',
    key: 'meterageDisplayCode',
    type: 'string',
    width: 150,
    rules: [
      {
        type: 'required'
      }
    ]
  },
  {
    index: 1,
    name: '子目号',
    key: 'meterageCode',
    type: 'string',
    width: 150,
    rules: [
      {
        type: 'required'
      }
    ]
  },
  {
    index: 2,
    name: '子目名称',
    key: 'meterageName',
    type: 'string',
    width: 360,
    rules: [
    ]
  },
  {
    index: 3,
    name: '单位',
    key: 'unit',
    type: 'string',
    width: 80,
    rules: [
    ]
  },
  {
    index: 4,
    name: '合同数量',
    key: 'num',
    type: 'number',
    width: 80,
    rules: [
    ]
  },
  {
    index: 5,
    name: '合同单价',
    key: 'price',
    type: 'number',
    width: 80,
    rules: [
    ]
  },
  {
    index: 6,
    name: '合同金额',
    key: 'amount',
    type: 'number',
    width: 80,
    rules: [
    ]
  },
  {
    index: 7,
    name: '剩余数量',
    key: 'remainNum',
    type: 'number',
    width: 80,
    rules: [
    ]
  }
]

export default meterageListHeaderColumns
